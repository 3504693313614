import { Trans } from 'react-i18next';
import logo from '../../assets/logo.png';
import classes from './NotFoundPage.module.css';

const NotFoundPage = () => {
  return (
    <main className={classes.main}>
      <img src={logo} alt="MN Development logo" />
      <p>
        <Trans i18nKey="not-found-page.not-found-text">
          URL not found. Go back to <a href="/">home</a>
        </Trans>
      </p>
    </main>
  );
};

export default NotFoundPage;
