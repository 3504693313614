import { act, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FloorPlan from '../FloorPlan/FloorPlan';
import RequestModal from '../RequestModal/RequestModal';
import classes from './Apartment.module.css';

const Apartment = ({ selectApartment, data, floor }) => {
  const { t } = useTranslation();
  const [activeImgType, setActiveImgType] = useState('2d');
  const [activeImg, setActiveImg] = useState(0);
  const [modalVis, setModalVis] = useState(false);

  return (
    <section className={classes.section}>
      <div className={classes.apartmentPlan}>
        <div className={classes.imagesWrapper}>
          {activeImgType === '2d' ? (
            <div className={classes.images}>
              <img
                className={classes.apartmentImg}
                src={data.image ? require(`../../${data.image}`) : ''}
                alt="Apartment 2D plan"
              />
            </div>
          ) : (
            <div className={classes.images}>
              <div style={{ transform: `translateX(-${activeImg * 100}%)`, display: 'flex' }}>
                <img
                  className={`${classes.apartmentImg} ${activeImg === 0 ? classes.active : ''}}`}
                  src={data['3d']?.[0] ? require(`../../${data['3d'][0]}`) : ''}
                  alt="Apartment 3D plan 1"
                />
                <img
                  className={`${classes.apartmentImg} ${activeImg === 1 ? classes.active : ''}}`}
                  src={data['3d']?.[1] ? require(`../../${data['3d'][1]}`) : ''}
                  alt="Apartment 3D plan 2"
                />
              </div>

              <div className={classes.arrowsWrapper}>
                <button onClick={() => activeImg > 0 && setActiveImg(activeImg - 1)}>
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    style={{ transform: 'rotate(180deg)' }}
                  >
                    <path
                      d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="#fff"
                    />
                  </svg>
                </button>

                <button onClick={() => activeImg < 1 && setActiveImg(activeImg + 1)}>
                  <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="#fff"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>

        {/* <div className={classes.imagesWrapper} style={{ transform: `translateX(-${activeImg * 100}%)` }}>
          <div className={classes.images}>
            <img
              className={classes.apartmentImg}
              src={data.image ? require(`../../${data.image}`) : ''}
              alt="Apartment 2D plan"
            />
          </div>

          <div className={classes.images}>

          </div>
        </div> */}

        <div className={classes.pagination}>
          <button onClick={() => setActiveImgType('2d')} className={activeImgType === '2d' ? classes.active : ''}>
            2D
          </button>
          <button onClick={() => setActiveImgType('3d')} className={activeImgType === '3d' ? classes.active : ''}>
            3D
          </button>
        </div>
      </div>

      <div className={classes.info}>
        <h2>
          {t('landing-page.type')} {data?.type}
        </h2>
        <ul>
          <li>
            {t('landing-page.size')}:<span>{data?.size} m</span>
            <sup>2</sup>
          </li>

          <li>
            {t('landing-page.bedroom')}:<span>{data?.bedroom}</span>
          </li>

          <li>
            <button onClick={() => setModalVis(true)} className={classes.requestBtn}>
              {t('landing-page.make-a-request')}
            </button>
          </li>
        </ul>
      </div>

      <FloorPlan
        getApartmentInfo={() => {}}
        selectApartment={selectApartment}
        selectedApartmentCoords={data?.coords}
        styles={classes}
      />

      {modalVis && <RequestModal setModalVis={setModalVis} floor={floor} apartment={data?.type} />}
    </section>
  );
};

export default Apartment;
