import classes from './ProjectPage.module.css';
import projectPhoto1 from '../../assets/project-photo1.png';
import projectPhoto2 from '../../assets/project-photo2.png';
import { useTranslation } from 'react-i18next';

const ProjectPage = () => {
  const { t } = useTranslation();

  return (
    <main className={classes.main}>
      <section>
        <img src={projectPhoto1} alt="Project render" />
        <p className={classes.firstText}>{t('project-page.text1')}</p>
      </section>

      <section>
        <p className={classes.secondText}>{t('project-page.text2')}</p>
        <img src={projectPhoto2} alt="Project render" />
      </section>
    </main>
  );
};

export default ProjectPage;
