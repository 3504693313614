import classes from './FloorSelectorMobile.module.css';
import floorsData from '../../data/floorsData.json';
import { useTranslation } from 'react-i18next';

const FloorSelectorMobile = () => {
  const { t } = useTranslation();

  const renderFloors = Object.entries(floorsData).map(([floor, data], i) => {
    return (
      <li key={floor}>
        <button onClick={() => (window.location.href = `floor/${i + 1}`)}>
          {t('landing-page.floor')} {i + 1}
          <span>
            {t('landing-page.apartments-left')} {data.apartmentsLeft}/8
          </span>
        </button>
      </li>
    );
  });

  return <ul className={classes.wrapper}>{renderFloors}</ul>;
};

export default FloorSelectorMobile;
