import { useTranslation } from 'react-i18next';
import FloorSelector from '../../components/FloorSelector/FloorSelector';
import FloorSelectorMobile from '../../components/FloorSelectorMobile/FloorSelectorMobile';
import classes from './LandingPage.module.css';

const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <main className={classes.main}>
      <div>
        <h1 className={classes.title}>{t('landing-page.title')}</h1>
        <p className={classes.tooltip}>{t('landing-page.select-the-floor-hover')}</p>
        <p className={classes.tooltipMobile}>{t('landing-page.select-the-floor')}</p>
      </div>

      <FloorSelector />
      <FloorSelectorMobile />
    </main>
  );
};

export default LandingPage;
