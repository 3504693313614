import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo.png';
import logoMobile from '../../assets/logo.png';
import fbLogo from '../../assets/facebook-logo.svg';
import classes from './Header.module.css';
import { facebookLink } from '../../constants';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem('language') || 'GEO');
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (newLang) => {
    setSelectedLang(newLang);
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  const changePage = function (pageName) {
    navigate(pageName);
    setMenuOpen(false);
  };

  const getClassName = function (path) {
    return location.pathname === path ? classes.active : classes.headerItem;
  };

  return (
    <header className={classes.header}>
      <div className={classes.headerContainer}>
        <div className={classes.leftSide}>
          <picture onClick={() => navigate('/')}>
            <source srcSet={logoMobile} media="(max-width: 900px)" />
            <img className={classes.logo} src={logo} alt="MN Development logo" />
          </picture>

          <nav className={`${classes.nav} ${menuOpen ? classes.openedMenu : ''}`}>
            <ul className={selectedLang === 'GEO' ? classes.fontSizeGeo : ''}>
              <li
                className={location.pathname === '/' || location.pathname.startsWith('/floor') ? classes.active : ''}
                onClick={() => changePage('/')}
              >
                {t('header.home')}
              </li>

              <li className={getClassName('/project')} onClick={() => changePage('/project')}>
                {t('header.project')}
              </li>

              <li className={getClassName('/apartments')} onClick={() => changePage('/apartments')}>
                {t('header.apartments')}
              </li>

              <li className={getClassName('/contact-us')} onClick={() => changePage('/contact-us')}>
                {t('header.contact-us')}
              </li>
            </ul>
          </nav>
        </div>

        <div className={classes.rightSide}>
          <div className={`${classes.icons} ${menuOpen ? classes.openedMenu : ''}`}>
            <button onClick={() => window.open(facebookLink, '_blank')}>
              <img width={18} height={18} src={fbLogo} alt="Facebook logo" />
            </button>
          </div>

          <div className={classes.langSwitcherWrapper}>
            <button className={classes.langSwitcherBtn}>{selectedLang}</button>

            <div className={classes.options}>
              <button
                onClick={handleLanguageChange.bind(null, 'ENG')}
                className={selectedLang === 'ENG' ? classes.active : ''}
              >
                <abbr title="English">ENG</abbr>
              </button>

              <button
                onClick={handleLanguageChange.bind(null, 'GEO')}
                className={selectedLang === 'GEO' ? classes.active : ''}
              >
                <abbr title="ქართული">GEO</abbr>
              </button>

              <button
                onClick={handleLanguageChange.bind(null, 'RUS')}
                className={selectedLang === 'RUS' ? classes.active : ''}
              >
                <abbr title="Русский">RUS</abbr>
              </button>
            </div>
          </div>

          <button aria-label="Open menu" className={classes.menuBtn} onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? (
              <svg width="48" height="46" viewBox="0 0 48 46">
                <path
                  d="M33 31.25L24 22.625M24 22.625L15 14M24 22.625L33 14M24 22.625L15 31.25"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg width="48" height="46" viewBox="0 0 40 40">
                <path
                  d="M8.80001 19.6C8.13727 19.6 7.60001 20.1373 7.60001 20.8C7.60001 21.4627 8.13727 22 8.80001 22V19.6ZM31.2 22C31.8627 22 32.4 21.4627 32.4 20.8C32.4 20.1373 31.8627 19.6 31.2 19.6V22ZM12.5344 26C11.8717 26 11.3344 26.5373 11.3344 27.2C11.3344 27.8627 11.8717 28.4 12.5344 28.4V26ZM27.4672 28.4C28.1299 28.4 28.6672 27.8627 28.6672 27.2C28.6672 26.5373 28.1299 26 27.4672 26V28.4ZM12.5344 13.2C11.8717 13.2 11.3344 13.7373 11.3344 14.4C11.3344 15.0627 11.8717 15.6 12.5344 15.6V13.2ZM27.4672 15.6C28.1299 15.6 28.6672 15.0627 28.6672 14.4C28.6672 13.7373 28.1299 13.2 27.4672 13.2V15.6ZM8.80001 22H31.2V19.6H8.80001V22ZM12.5344 28.4H27.4672V26H12.5344V28.4ZM12.5344 15.6H27.4672V13.2H12.5344V15.6Z"
                  fill="#162522"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      <div className={`${classes.menu} ${menuOpen ? classes.openedMenu : ''}`}></div>
    </header>
  );
};

export default Header;
