export const facebookLink = 'https://www.facebook.com/MNDevelopment.ge';

export const whatsappLink = 'https://wa.me/+995595008166';

export const viberLink = 'viber://chat/?number=+995595008166';

export const telegramLink = 'https://t.me/+995595008166';

export const phoneLink = 'tel:+995595008166';

export const email = 'info@mndevelopment.ge';

export const hoverColor = 'rgba(90, 201, 0, 0.4)';

export const hoverStrokeColor = 'rgba(90, 201, 0, 0.7)';
