import { useTranslation } from 'react-i18next';
import ApartmentType from '../../components/ApartmentType/ApartmentType';
import type1 from '../../assets/bina1.webp';
import type2 from '../../assets/bina2.webp';
import type3 from '../../assets/bina3.webp';
import type4 from '../../assets/bina4.webp';
import type5 from '../../assets/bina5.webp';
import type6 from '../../assets/bina6.webp';
import type7 from '../../assets/bina7.webp';
import type8 from '../../assets/bina8.webp';
import classes from './ApartmentsPage.module.css';

const ApartmentsPage = () => {
  const { t } = useTranslation();

  return (
    <main className={classes.main}>
      <div className={classes.types}>
        <div className={classes.titleWrapper}>
          <h1>{t('apartments-page.types-of-apartments')}</h1>
          <p>{t('apartments-page.apartments-text')}</p>
        </div>
        <ApartmentType image={type1} type={'type1'} size={73.1} />
        <ApartmentType image={type2} type={'type2'} size={36.1} />
        <ApartmentType image={type3} type={'type3'} size={32.8} />
        <ApartmentType image={type4} type={'type4'} size={77.4} />
        <ApartmentType image={type5} type={'type5'} size={33.9} />
        <ApartmentType image={type6} type={'type6'} size={43.0} />
        <ApartmentType image={type7} type={'type7'} size={51.2} />
        <ApartmentType image={type8} type={'type8'} size={72.1} />
      </div>
    </main>
  );
};

export default ApartmentsPage;
