import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ApartmentSelector from '../../components/ApartmentSelector/ApartmentSelector';
import Apartment from '../../components/Apartment/Apartment';
import floorsData from '../../data/floorsData.json';
import backArrow from '../../assets/goback-icon.svg';
import classes from './ApartmentSelectorPage.module.css';

const ApartmentSelectorPage = () => {
  const { t } = useTranslation();
  const [activeApartment, setActiveApartment] = useState(JSON.parse(localStorage.getItem('selectedApartment')) || null);
  const { floorNum } = useParams();
  const navigate = useNavigate();

  const selectApartment = function (data) {
    localStorage.setItem('selectedApartment', JSON.stringify(data));
    setActiveApartment(data);
  };

  useEffect(() => {
    return () => {
      selectApartment(null);
    };
  }, []);

  return (
    <main className={classes.main}>
      <div className={classes.header}>
        {!activeApartment ? (
          <button className={classes.backBtn} onClick={() => navigate('/')}>
            <img width={42} height={11} src={backArrow} alt="Go back" />
            {t('landing-page.floor-selection')}
          </button>
        ) : (
          <button className={classes.backBtn} onClick={() => selectApartment(null)}>
            <img width={42} height={11} src={backArrow} alt="Go back" />
            {t('landing-page.apartment-selection')}
          </button>
        )}

        <h2 className={classes.apartmentsLeft}>
          {t('landing-page.apartments-left')}: {floorsData[`floor${floorNum}`]?.apartmentsLeft}
        </h2>
      </div>

      {!activeApartment ? (
        <ApartmentSelector selectApartment={selectApartment} />
      ) : (
        <Apartment selectApartment={selectApartment} data={activeApartment} floor={floorNum} />
      )}
    </main>
  );
};

export default ApartmentSelectorPage;
