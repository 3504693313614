import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import classes from './RequestModal.module.css';

const RequestModal = ({ setModalVis, floor = 0, apartment = 0 }) => {
  const [formData, setFormData] = useState({ name: '', number: '', message: '' });
  const [status, setStatus] = useState('');
  const { t } = useTranslation();

  const closeModal = () => {
    setModalVis(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(t('landing-page.sending'));

    try {
      const response = await fetch(
        'https://mn-varketili-cqedcgb9esg5hddd.westeurope-01.azurewebsites.net/api/request/send-request',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.name.trim(),
            phone: formData.number.trim(),
            message: formData.message.trim(),
            floor,
            apartment,
          }),
        }
      );

      if (response.status === 429) {
        setStatus(t('landing-page.too-many-requests'));
        return;
      }

      const result = await response.json();

      if (result.success) {
        setStatus(t('landing-page.request-sent-successfully'));
        setFormData({ name: '', number: '', message: '' });
      } else {
        setStatus(t('landing-page.error-sending-request'));
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus(t('landing-page.failed-to-send-request'));
    }
  };

  return (
    <>
      <div className={classes.wrapper} open>
        <div className={classes.titleWrapper}>
          <h4>{t('landing-page.make-a-request')}</h4>

          <button onClick={closeModal} className={classes.closeBtn}>
            <svg width="30" height="29" viewBox="0 0 48 46">
              <path
                d="M33 31.25L24 22.625M24 22.625L15 14M24 22.625L33 14M24 22.625L15 31.25"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        <form className={classes.form} onSubmit={handleSubmit}>
          <label htmlFor="name">{t('landing-page.full-name')}</label>
          <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} required />

          <label htmlFor="number">{t('landing-page.phone-number')}</label>
          <input type="number" name="number" id="number" value={formData.number} onChange={handleChange} required />

          <textarea
            name="message"
            id="message"
            value={formData.message}
            onChange={handleChange}
            placeholder={t('contact-us-page.message-us')}
          ></textarea>

          <button className={classes.sendBtn}>Send Request</button>
          <p>{status}</p>
        </form>
      </div>

      <div className={classes.backdrop} onClick={closeModal} />
    </>
  );
};

export default RequestModal;
