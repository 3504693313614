import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FloorPlan from '../FloorPlan/FloorPlan';
import backArrow from '../../assets/goback-icon.svg';
import classes from './ApartmentSelector.module.css';

const ApartmentSelector = ({ selectApartment }) => {
  const { t } = useTranslation();
  const [hoveredApartment, setHoveredApartment] = useState(null);
  const { floorNum } = useParams();
  const navigate = useNavigate();

  const getApartmentInfo = function (data) {
    setHoveredApartment(data);
  };

  const higherFloor = function () {
    if (parseInt(floorNum) === 9) return;

    navigate(`/floor/${parseInt(floorNum) + 1}/`);
  };

  const lowerFloor = function () {
    if (parseInt(floorNum) === 1) return;

    navigate(`/floor/${parseInt(floorNum) - 1}/`);
  };

  return (
    <section className={classes.section}>
      <FloorPlan getApartmentInfo={getApartmentInfo} selectApartment={selectApartment} styles={classes} />

      <div className={classes.apartmentInfoWrapper}>
        <div className={classes.floorSelection}>
          {floorNum != 9 && (
            <button onClick={higherFloor}>
              <img width={42} height={12} style={{ rotate: '90deg' }} src={backArrow} alt="Higher floor" />
            </button>
          )}

          <h1>
            {floorNum} <sub>/9</sub>
          </h1>

          {floorNum != 1 && (
            <button onClick={lowerFloor}>
              <img width={42} height={12} style={{ rotate: '-90deg' }} src={backArrow} alt="Lower floor" />
            </button>
          )}
        </div>

        <div className={`${classes.apartmentInfo} ${hoveredApartment ? classes.showApartemntInfo : ''}`}>
          <ul>
            <li>
              {t('landing-page.size')}:<span>{hoveredApartment?.size} m</span>
              <sup>2</sup>
            </li>
            <li>
              {t('landing-page.bedroom')}:<span>{hoveredApartment?.bedroom}</span>
            </li>
          </ul>

          <img
            className={classes.apartmentPreview}
            src={hoveredApartment ? require(`../../${hoveredApartment?.image}`) : ''}
            alt={`${hoveredApartment?.name} plan`}
          />
        </div>
      </div>
    </section>
  );
};

export default ApartmentSelector;
