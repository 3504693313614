import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import floorsData from '../../data/floorsData.json';
import mainBuilding from '../../assets/main-building.webp';
import classes from './FloorSelector.module.css';
import { hoverColor, hoverStrokeColor } from '../../constants';

const FloorSelector = () => {
  const { t } = useTranslation();
  const [hoveredCoords, setHoveredCoords] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [imageWidth, setImageWidth] = useState(1280);
  const [activeFloor, setActiveFloor] = useState({ floor: 0, apartmentsLeft: 0 });
  const [tooltipVis, setTooltipVis] = useState(false);
  const [tooltipAnim, setTooltipAnim] = useState();
  const canvasRef = useRef(null);
  const imgRef = useRef(null);

  const correctPixels = function (coordsArr) {
    if (window.innerWidth > 1280) {
      return coordsArr.map((point) => [point[0], point[1]]);
    }

    return coordsArr.map((point) => [point[0] - (1280 - window.innerWidth) / 2, point[1]]);
  };

  const drawBlinkingTooltip = (ctx) => {
    let opacity = 0.3;
    let strokeOpacity = 0.7;
    let increasing = false;
    let increasingStrokeOpacity = false;

    const animate = () => {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const coords = correctPixels(floorsData.floor9.coords);

      ctx.beginPath();
      ctx.moveTo(coords[0][0], coords[0][1]);
      coords.forEach((point) => {
        ctx.lineTo(point[0], point[1]);
      });
      ctx.closePath();

      ctx.strokeStyle = `rgba(142, 201, 95, ${strokeOpacity})`;
      ctx.fillStyle = `rgba(142, 201, 95, ${opacity})`;
      ctx.fill();
      ctx.stroke();

      opacity += increasing ? 0.004 : -0.004;
      if (opacity <= 0 || opacity >= 0.4) increasing = !increasing;

      strokeOpacity += increasingStrokeOpacity ? 0.007 : -0.007;
      if (strokeOpacity <= 0 || strokeOpacity >= 0.7) increasingStrokeOpacity = !increasingStrokeOpacity;

      setTooltipAnim(requestAnimationFrame(animate));
    };

    animate();
  };

  const drawPoly = function (ctx) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    if (hoveredCoords.length === 0) return;

    cancelAnimationFrame(tooltipAnim);

    const correctedPixels = correctPixels(hoveredCoords);

    ctx.beginPath();
    ctx.moveTo(correctedPixels[0][0], correctedPixels[0][1]);
    correctedPixels.forEach((point) => {
      ctx.lineTo(point[0], point[1]);
    });
    ctx.closePath();

    ctx.fillStyle = hoverColor;
    ctx.fill();
    ctx.stroke();
  };

  const handleResize = function () {
    const imgWidth = imgRef?.current?.offsetWidth;

    setImageWidth(imgWidth);
  };

  const handleMouseOver = function (coords, e) {
    const floor = e.target.dataset.floor;
    const apartmentsLeft = floorsData[`floor${floor}`].apartmentsLeft;

    if (window.innerWidth > 900) {
      setTooltipVis(true);
    }

    setActiveFloor({ floor: floor, apartmentsLeft: apartmentsLeft });
    setHoveredCoords(coords);
  };

  const handleMouseOut = function () {
    setHoveredCoords([]);
    setTooltipVis(false);
  };

  const handleMouseMove = function (e) {
    const element = canvasRef.current;
    const rect = element.getBoundingClientRect();
    const x = e.clientX - rect.left + 40;
    const y = e.clientY - rect.top - 28;

    setMousePosition({ x, y });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.strokeStyle = hoverStrokeColor;
    ctx.lineWidth = 2;

    drawPoly(ctx);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [hoveredCoords]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    drawBlinkingTooltip(ctx);
  }, []);

  return (
    <section className={classes.section}>
      <div className={classes.building}>
        <img
          ref={imgRef}
          width={1280}
          height={622}
          className={classes.visibleImg}
          src={mainBuilding}
          alt="Building render"
          onLoad={handleResize}
        />
        <canvas ref={canvasRef} width={imageWidth} height={622} />
        <img
          width={1280}
          height={622}
          className={classes.overlayImg}
          src={mainBuilding}
          alt="Building render"
          useMap="#floorMap"
        />

        <div
          className={classes.tooltip}
          style={{ display: tooltipVis ? 'block' : 'none', left: mousePosition.x, top: mousePosition.y }}
        >
          <div />
          <p>
            {t('landing-page.floor')}: {activeFloor.floor}
          </p>
          <p>
            <span>{activeFloor.apartmentsLeft}/8</span> {t('landing-page.apartments-left')}
          </p>
        </div>
      </div>

      <map name="floorMap">
        {Object.entries(floorsData).map(([floor, data], i) => {
          return (
            <area
              key={floor}
              data-floor={i + 1}
              shape="poly"
              coords={correctPixels(data.coords).flat().join(',')}
              href={`floor/${i + 1}`}
              alt={floor}
              onMouseOut={handleMouseOut}
              onMouseOver={handleMouseOver.bind(null, data.coords)}
              onMouseMove={handleMouseMove}
            />
          );
        })}
      </map>
    </section>
  );
};

export default FloorSelector;
