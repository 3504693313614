import { useTranslation } from 'react-i18next';
import apartmentsData from '../../data/apartments.json';
import classes from './ApartmentType.module.css';

const ApartmentType = ({ image, type }) => {
  const { t } = useTranslation();
  const data = apartmentsData[type];

  return (
    <div className={classes.type} style={{ animationDuration: `${parseInt(data.type) * 100 + 500}ms` }}>
      <img src={image} alt="" />

      <div>
        <h2>
          {t('landing-page.type')} {data.type}
        </h2>
        <h3>
          {data.size.toFixed(1)} m<sup>2</sup>
        </h3>
        <p>
          {data.bedroom} {t('landing-page.bedroom')}
        </p>
      </div>
    </div>
  );
};

export default ApartmentType;
