import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import floorsData from '../../data/floorsData.json';
import aparmentsData from '../../data/apartments.json';
import floorPlan from '../../assets/floor-plan.webp';
import { hoverColor } from '../../constants';

const FloorPlan = ({ getApartmentInfo, selectApartment, selectedApartmentCoords = null, styles }) => {
  const [imageDimensions, setImageDimensions] = useState({ imgWidth: 960, imgHeight: 526 });
  const [hoveredCoords, setHoveredCoords] = useState([]);
  const { floorNum } = useParams();
  const canvasRef = useRef(null);
  const imgRef = useRef(null);

  const convertToPixel = function (coordsArr) {
    const { imgWidth, imgHeight } = imageDimensions;
    return coordsArr.map((point) => [(point[0] / 100) * imgWidth, (point[1] / 100) * imgHeight]);
  };

  const drawSoldApartments = function (ctx) {
    for (const [type, value] of Object.entries(floorsData[`floor${parseInt(floorNum)}`].typesSold)) {
      if (value) {
        const pointsInPixels = convertToPixel(aparmentsData[type].coords);

        ctx.beginPath();
        ctx.moveTo(pointsInPixels[0][0], pointsInPixels[0][1]);
        pointsInPixels.forEach((point) => {
          ctx.lineTo(point[0], point[1]);
        });
        ctx.closePath();

        ctx.fillStyle = hoverColor;
        ctx.fill();
      }
    }
  };

  const drawSelectedApartment = function (ctx) {
    if (!selectedApartmentCoords) return;

    const pointsInPixels = convertToPixel(selectedApartmentCoords);

    ctx.beginPath();
    ctx.moveTo(pointsInPixels[0][0], pointsInPixels[0][1]);
    pointsInPixels.forEach((point) => {
      ctx.lineTo(point[0], point[1]);
    });
    ctx.closePath();

    ctx.fillStyle = hoverColor;
    ctx.fill();
  };

  const drawPoly = function (ctx) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    if (hoveredCoords.length === 0) return;

    const pointsInPixels = convertToPixel(hoveredCoords);

    ctx.beginPath();
    ctx.moveTo(pointsInPixels[0][0], pointsInPixels[0][1]);
    pointsInPixels.forEach((point) => {
      ctx.lineTo(point[0], point[1]);
    });
    ctx.closePath();

    ctx.fillStyle = hoverColor;
    ctx.fill();
  };

  const resizeCanvas = function () {
    if (imgRef.current) {
      setImageDimensions({ imgWidth: imgRef.current.offsetWidth, imgHeight: imgRef.current.offsetHeight });
    }
  };

  const handleMouseOver = function (coords, type) {
    setHoveredCoords(coords);
    getApartmentInfo(aparmentsData[type]);
  };

  const handleMouseOut = function () {
    setHoveredCoords([]);
    getApartmentInfo(null);
  };

  useEffect(() => {
    const imgElement = imgRef.current;

    const handleImageLoad = () => {
      resizeCanvas();
    };

    if (imgElement?.complete) {
      handleImageLoad();
    } else {
      imgElement?.addEventListener('load', handleImageLoad);
    }

    window.addEventListener('resize', handleImageLoad);

    return () => {
      imgElement?.removeEventListener('load', handleImageLoad);
      window.removeEventListener('resize', handleImageLoad);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    drawPoly(ctx);
    drawSelectedApartment(ctx);
    drawSoldApartments(ctx);
  }, [imageDimensions, hoveredCoords, floorNum]);

  return (
    <section className={styles.planWrapper}>
      <div>
        <img ref={imgRef} className={styles.visibleImg} src={floorPlan} alt="Floor 1 plan" />
        <canvas
          ref={canvasRef}
          className={styles.canvas}
          width={imageDimensions.imgWidth}
          height={imageDimensions.imgHeight}
        />
        <img className={styles.overlayImg} src={floorPlan} alt="Floor 1 plan" useMap="#apartmentsPlanMap" />
      </div>

      <map name="apartmentsPlanMap">
        {Object.entries(aparmentsData).map(([type, data]) => {
          if (floorsData[`floor${parseInt(floorNum)}`].typesSold[type]) return '';

          return (
            <area
              key={type}
              shape="poly"
              coords={convertToPixel(data.coords).flat().join(',')}
              alt={type}
              onMouseOut={handleMouseOut}
              onMouseOver={handleMouseOver.bind(null, data.coords, type)}
              onClick={selectApartment.bind(null, data)}
            />
          );
        })}
      </map>
    </section>
  );
};

export default FloorPlan;
