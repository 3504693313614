import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import ApartmentsPage from './pages/ApartmentsPage/ApartmentsPage';
import ApartmentSelectorPage from './pages/ApartmentSelectorPage/ApartmentSelectorPage';
import ContactPage from './pages/ContactPage/ContactPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import App from './App';
import './index.css';
import './i18n';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: '', element: <LandingPage /> },
      { path: 'project', element: <ProjectPage /> },
      { path: 'apartments', element: <ApartmentsPage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'floor/:floorNum', element: <ApartmentSelectorPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router}></RouterProvider>);
