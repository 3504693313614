import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './langs/en.json';
import kaTranslation from './langs/ka.json';
import ruTranslation from './langs/ru.json';

const savedLanguage = localStorage.getItem('language') || 'GEO';

i18n.use(initReactI18next).init({
  resources: {
    ENG: {
      translation: enTranslation,
    },
    GEO: {
      translation: kaTranslation,
    },
    RUS: {
      translation: ruTranslation,
    },
  },
  lng: savedLanguage,
  interpolation: {
    escapeValue: false,
  },
});
