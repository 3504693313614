import classes from './MapComponent.module.css';
import { useEffect, useRef } from 'react';
import { OSM } from 'ol/source';
import { Feature, Map, View } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import marker from '../../assets/marker.svg';

const buildingCoords = fromLonLat([44.890549, 41.694394]);

const viewCenter = window.innerWidth <= 900 ? buildingCoords : fromLonLat([44.82131598179544, 41.69530898635433]);

const MapComponent = () => {
  const mapRef = useRef();

  const iconFeature = new Feature({
    geometry: new Point(buildingCoords),
  });

  const iconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: marker,
      scale: 0.5,
    }),
  });

  iconFeature.setStyle(iconStyle);

  const vectorSource = new VectorSource({
    features: [iconFeature],
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
  });

  useEffect(() => {
    const osmLayer = new TileLayer({
      preload: Infinity,
      source: new OSM(),
    });

    const map = new Map({
      target: mapRef.current,
      layers: [osmLayer, vectorLayer],
      view: new View({
        center: viewCenter,
        zoom: 13,
      }),
    });

    map.on('pointermove', (event) => {
      const pixel = map.getEventPixel(event.originalEvent);
      const hit = map.hasFeatureAtPixel(pixel);

      if (hit) {
        map.getTargetElement().style.cursor = 'pointer';
      } else {
        map.getTargetElement().style.cursor = '';
      }
    });

    map.on('click', (event) => {
      const pixel = map.getEventPixel(event.originalEvent);
      const feature = map.forEachFeatureAtPixel(pixel, (feature) => feature);

      if (feature) {
        window.open('https://maps.app.goo.gl/d5nHPpqMQeGDdE7L6', '_blank');
      }
    });

    return () => map.setTarget(null);
  }, []);

  return <div ref={mapRef} className={classes.mapWrapper}></div>;
};

export default MapComponent;
